import moment from 'moment'
import Define from '../../constants/define'
import { DataTableParam } from '../../features/stock/stockscreen/component/model'
import { DetailInterfaceAttendu, ReferenceAttendu } from '../../models'
import { t } from 'i18next'
import { removeTonesUpperCase } from '../removeTones'

export const mapDataDetail = (detailData: DataTableParam[][]) => {
  let mapData: DetailInterfaceAttendu[] = []
  let demo: any = {}

  for (let i = 0; i < detailData.length; i++) {
    for (let j = 0; j < detailData[i].length; j++) {
      demo[detailData[i][j].name] = detailData[i][j].value
    }

    mapData.push(demo)
    demo = {}
  }

  let att_production: ReferenceAttendu[] = mapData.map(
    ({
      ref_fournisseur: ref_fournisseur_number,
      reference: reference_number,
      qte: quantite,
      cdn: conditionement,
      modification_by,
      sscc,
      sscc_ob,
      ref_change,
      ref_block,

      ...rest
    }) => ({
      ref_fournisseur_number,
      reference_number,
      quantite,
      conditionement,
      ref_change: Boolean(ref_change),
      ref_block: Boolean(ref_block),
      modification_by: localStorage.getItem(Define.USERNAME) || '',
      sscc_ob: {
        num: sscc,
      },
      sscc,
      ...rest,
    })
  )

  for (let i = 0; i < att_production.length; i++) {
    att_production[i].quantite = Number(att_production[i].quantite) || 0
    att_production[i].sous_qte = Number(att_production[i].sous_qte) || 0
    att_production[i].total_pieses = Number(att_production[i].total_pieses) || 0
    att_production[i].dluo =
      moment(att_production[i].dluo, t('date-format')).unix() || 0
    att_production[i].volume_qte = Number(att_production[i].volume_qte) || 0
    att_production[i].volume_sousqte =
      Number(att_production[i].volume_sousqte) || 0
    att_production[i].poids_qte = Number(att_production[i].poids_qte) || 0
    att_production[i].poids_sousqte =
      Number(att_production[i].poids_sousqte) || 0
    att_production[i].validation_time =
      Number(att_production[i].validation_time) || 0
    att_production[i].lot = removeTonesUpperCase(
      att_production[i].lot || ''
    ).trim()

    if (!att_production[i].sscc_ob?.num) {
      delete att_production[i]['sscc_ob']
      delete att_production[i]['sscc']
      delete att_production[i]['sscc_id']
    }
    if (att_production[i].is_standard?.toString() === 'true') {
      att_production[i].is_standard = true
    } else att_production[i].is_standard = false
  }

  return att_production
}
