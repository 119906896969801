import baseAxios from './clientAPI'

interface ResponseSSCC {
  code?: number
  data?: any
  error?: any
  status?: string
}

const ssccApi = {
  checkSSCC(sscc: string): Promise<ResponseSSCC> {
    const url = `/warehouse/sscc/check?sscc=${sscc}`
    return baseAxios.get(url)
  },
}

export default ssccApi
