import { LockOutlined } from '@ant-design/icons'
import { Col, Form, Popconfirm, Row, Spin } from 'antd'
import { cloneDeep, isEmpty, isUndefined, uniqBy } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import Define from '../../../constants/define'
import { InputType } from '../../../enum/InputType'
import { MODE } from '../../../enum/mode'
import '../../../features/stock/modal/CustomModal.scss'
import '../../../features/stock/modal/type/attendu/Attendu.scss'
import {
  checkIsQteStandard,
  getIndexes,
} from '../../../features/stock/modal/type/commande/func'
import ITable from '../../../features/stock/stockscreen/component/ITable'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  FormatRowCol,
  ObserveAndTransform,
  OptionType,
  TitleParam,
} from '../../../features/stock/stockscreen/component/model'
import SectionLayout from '../../../features/stock/stockscreen/component/SectionLayout'
import {
  CInput,
  InvalidProperty,
  NameAndValue,
  ResetProperty,
} from '../../../features/stock/stockscreen/component/Wrapper'
import usePrevious from '../../../hook/previousHook'
import referenceApi, { EntryFind } from '../../../http/referenceApi'
import ssccApi from '../../../http/ssccApi'
import { CommonError } from '../../../models/common/error'
import { changeDetailSection } from '../../../redux/reducers/attenduSlice'
import {
  isGreatThan,
  isLessThan,
  randomID,
  removeTonesUpperCase,
} from '../../../utils'
import { checkSSCC } from '../../../utils/barcode'
import CSwitch from '../CSwitch/CSwitch'
import firstLine from './init-data.json'
import {
  getStateOfTotalPiesesAndSousQte,
  getSomeIndex,
  getTotalPieses,
  onChangeOnSousQte,
  onChangeTotalPieses,
  onResetForShowError,
} from './func'
import { focusById } from '../../../hook/usePressTab'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { Conditionnement } from '../../../models'
import define from '../../../constants/define'

interface ActionProps {
  isFilter?: boolean
  isDisablePlusBtn?: boolean
  isDisableFilter?: boolean

  onToggleFilterFournisseur?(value: boolean): void

  onClickPlusBtn?(): void

  onChangePhotos?(value: number): void

  photoValue?: number
  detailLength: number
}

const Action = (props: ActionProps) => {
  const attenduKey = useSelector((state: RootState) => state.selector).data
    .attendu
  //const [numberOfPhotos, setNumberOfPhotos] = useState<number>(0);
  const onChangeFilterFournisseur = (value: boolean) => {
    if (props?.onToggleFilterFournisseur) {
      props?.onToggleFilterFournisseur(value)
    }
  }

  const onChangePhotosNumber = (value: NameAndValue) => {
    if (props.onChangePhotos) {
      props.onChangePhotos(Number(value.value))
    }
  }

  const onClickPlusBtn = (): void => {
    if (props?.isDisablePlusBtn) return
    if (props?.onClickPlusBtn) props.onClickPlusBtn()
  }

  function getNextFocusIds() {
    let nextId = ''
    for (let i = 0; i < props.detailLength; i++) {
      nextId = `${nextId}, attendureference-${i}, attenduicon-edit-${i}`
    }
    return `${nextId}, ${attenduKey['Commentaire']}`
  }

  return (
    <Row gutter={32} align="middle" className="detail-action">
      <Col className="flex items-center gap-3">
        <Row gutter={6} align="middle">
          <Col className="flex items-center">
            <span className="form-label">Filtre fournisseur</span>
            <div className="gap-hor-4"></div>
            <CSwitch
              id={attenduKey['Filtre-fournisseur']}
              previousId={`${attenduKey['Code-Nom-Fournisseur']}, ${attenduKey['N-Commande']}`}
              nextId={attenduKey['Photos']}
              disabled={props?.isDisableFilter}
              checked={props?.isFilter}
              onChange={onChangeFilterFournisseur}
            />
          </Col>
          {props?.isDisableFilter && (
            <Col>
              <LockOutlined style={{ color: 'red' }} />
            </Col>
          )}
        </Row>
      </Col>
      <Col className="flex items-center gap-3">
        <span className="form-label">Photo(s):</span>
        <span className="inline-block" style={{ width: 50 }}>
          <CInput
            id={attenduKey['Photos']}
            previousId={attenduKey['Filtre-fournisseur']}
            nextId={getNextFocusIds()}
            maxLength={2}
            defaultValue="0"
            type={[InputType.NUMBER_INTEGER_POS]}
            onChange={onChangePhotosNumber}
            value={props.photoValue || 0}
            validates={[
              {
                message: ' ',
                validateFunction: (value) => isGreatThan(Number(value), -1),
                order: 0,
                realtime: true,
              },
              {
                message: ' ',
                validateFunction: (value) => isLessThan(Number(value), 21),
                order: 0,
                realtime: true,
              },
            ]}
            className="w-11 custom-input"
          />
        </span>
      </Col>
      <Col className="flex items-center gap-3">
        <i
          className={`${
            props?.isDisablePlusBtn ? 'icon-add-plus-disable' : 'icon-add-plus'
          }`}
          onClick={onClickPlusBtn}
        ></i>
      </Col>
    </Row>
  )
}

interface DetailProps {
  mode?: MODE
  fournisseur_id?: string
  onChangeData?: (value: DataTableParam[][]) => void
  onCheckValid?: (value: boolean) => void
  alert?: ReactNode
  detailError?: CommonError[]
  initData?: DataTableParam[][]
  onChangeInit?: (isInit: boolean) => void
  onChangePhoto?: (photos: number) => void
  valuePhoto?: number
  client?: string
}

const firstLineData = JSON.parse(JSON.stringify(firstLine)) as DataTableParam[]
firstLineData.push({ name: 'uuid', value: randomID('detail-line') })
const initData: DataTableParam[][] = [firstLineData]

const formatCols: FormatRowCol[] = []

const cInputProps = {
  className: 'border-children-cell-table text-center',
  requiredMessage: '',
  validates: [],
}

const defaultPromise: Promise<OptionType[]> = Promise.resolve([])

function updateCDNRelateData(
  currentCDN: Conditionnement,
  currentLine: DataTableParam[],
  volumeQteIndex: number,
  conditionementIDIndex: number,
  poidsQteIndex: number,
  conditionementNameIndex: number
) {
  let volumeQte =
    Number(currentCDN.longueur) *
    Number(currentCDN.largeur) *
    Number(currentCDN.hauteur)
  currentLine[volumeQteIndex].value = volumeQte.toString()
  currentLine[conditionementIDIndex].value = currentCDN.id
  currentLine[poidsQteIndex].value = currentCDN.poids_brut?.toString()
  currentLine[conditionementNameIndex].value = currentCDN.nom
}

const ModalDetailComponent = (props: DetailProps) => {
  const dispatch = useDispatch()
  const attenduKey = useSelector((state: RootState) => state.selector).data
    .attendu
  const [loading, setLoading] = useState<boolean>(false)

  const [header, setHeader] = useState<TitleParam[]>([
    {
      label: 'Référence',
      required: true,
      name: 'reference',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.C_SEARCH],
        width: 200,
        cellClass: 'py-3',
      },
    },

    {
      label: 'Réf fournisseur',
      name: 'ref_fournisseur',
      cInputProps: {
        ...cInputProps,
        validates: [],
        requiredMessage: '',
        type: [InputType.C_SEARCH],
        placeHolder: '-',
        width: 200,
      },
    },

    {
      label: 'Libellé',
      required: true,
      name: 'libelle',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        disabled: true,
        className: `w-16 + ${cInputProps.className}`,
      },
    },
    {
      label: 'Lot',
      name: 'lot',

      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-28',
        formatOnBlur: true,
        maxLength: 20,
        placeHolder: '-',
        format: removeTonesUpperCase,
      },
    },
    {
      label: 'SSCC',
      name: 'sscc',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-30',
        type: [InputType.NUMBER_INTEGER_POS],
        formatOnBlur: true,
        maxLength: 18,
        placeHolder: '-',
        validates: [
          {
            message: 'Entrée invalide',
            validateFunction: checkSSCC,
            order: 0,
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'DLUO',
      name: 'dluo',

      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 15,
        formatOnBlur: true,
        type: [InputType.DATE],
        placeHolder: '-',
      },
    },
    {
      label: 'Cdn',
      required: true,
      name: 'cdn',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        className: cInputProps.className + ' w-26',
        maxLength: 100,
        formatOnBlur: true,
        type: [InputType.SELECT],
        placeHolder: 'Selectionnez',
      },
    },
    {
      label: 'Qte',
      required: true,
      name: 'qte',
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 4,
        format: (value) => `${Number(value)}`,
        formatOnBlur: true,
        type: [InputType.NUMBER_INTEGER_POS],
        validates: [
          {
            message: 'Quantité doit être supérieure à 0',
            validateFunction: (value) => isGreatThan(Number(value), 0),
            order: 0,
            realtime: true,
          },
        ],
      },
    },

    {
      label: 'Sous-Qte',
      name: 'sous_qte',
      required: true,
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        maxLength: 5,
        type: [InputType.NUMBER_INTEGER_POS],
        format: (value) => (value ? `${Number(value)}` : ''),
        formatOnBlur: true,
        disabled: true,
        placeHolder: '',
        validates: [
          {
            name: 'greatThan0',
            order: 0,
            validateFunction: (value) => {
              if (value === '-') value = '1'
              return isGreatThan(Number(value), 0)
            },
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 0,
            validateFunction: (value) => {
              if (value === '-') return true
              return isLessThan(Number(value), 10001)
            },

            message: 'Max = 10.000',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Total pièces',
      required: true,
      name: 'total_pieses',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        type: [InputType.NUMBER_INTEGER_POS],
        maxLength: 13,
        format: (value) => `${Number(value)}`,
        formatOnBlur: true,
        validates: [
          {
            name: 'greatThan0',
            order: 0,
            validateFunction: (value) => {
              if (value === '-') value = '1'
              return isGreatThan(Number(value), 0)
            },
            message: 'La valeur doit être supérieure à 0',
            realtime: true,
          },
          {
            order: 0,
            validateFunction: (value) =>
              isLessThan(Number(value), 1000000000001),
            message: 'Max = 1.000.000.000.000',
            realtime: true,
          },
        ],
      },
    },
  ])
  const [data, setData] = useState<DataTableParam[][]>(
    (props?.initData || initData).map((line) =>
      line.map((item) => {
        if (item?.label) {
          const index = line.findIndex((i) => i.name === item.name)
          line[index].value = item.value
          line[index].options = [
            {
              label: item.label,
              value: item.value,
            },
          ]
        }
        return item
      })
    )
  )
  const form = Form.useFormInstance()
  // const [reference, setReference] = useState<EntryFind | undefined>();
  const [references, setReferences] = useState<(EntryFind | undefined)[]>([])

  const [invalidList, setInvalidList] = useState<InvalidProperty[][]>([[]])
  const [actions, setActions] = useState<ActionParam[]>([])
  const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([
    [],
  ])
  const [forceShowError, setForceShowError] = useState<ForceShowError[][]>([[]])
  const [disableLines, setDisableLines] = useState<number[]>([])
  const [hideCols] = useState<string[]>([])
  const [formatRows, setFormatRows] = useState<FormatRowCol[][]>([])
  const [resetFlags, setResetFlags] = useState<boolean[]>([])
  const [observeAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const currentFournisseur = useSelector(
    (state: RootState) => state.fournisseur.currentFournisseur
  )
  const currentFournisseurEdit = useSelector(
    (state: RootState) => state.fournisseur.currentFournisseurEdit
  )

  const [forceShowWarning, setForceShowWarning] = useState<
    ForceShowWarning[][]
  >([[]])
  const [forceReset, setForceReset] = useState<ResetProperty[][]>([[]])
  const [isInit, setIsInit] = useState<boolean>(true)
  const [blockTable, setBlockTable] = useState<boolean>(false)
  const prevCurrentFournisseur = usePrevious(currentFournisseur)
  const prevCurrentFournisseurEdit = usePrevious(currentFournisseurEdit)
  const [isFilterFournisseur, setFilterFournisseur] = useState<boolean>(true)
  const [currentCdns, setCurrentCdns] = useState<Conditionnement[]>([])

  useEffect(() => {
    const actionsClone = cloneDeep(actions) as ActionParam[]

    for (let i = 0; i < listNotFillRequired.length; i++) {
      if (isEmpty(listNotFillRequired[i])) {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: true,
        }
      } else {
        actionsClone[i] = {
          ...actionsClone[i],
          isFillAllRequired: false,
          isValid: false,
        }
      }
    }
    for (let i = 0; i < invalidList.length; i++) {
      if (isEmpty(invalidList[i]))
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: true,
        }
      else
        actionsClone[i] = {
          ...actionsClone[i],
          isValid: false,
        }
    }
    setActions(actionsClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNotFillRequired, invalidList])

  useEffect(() => {
    if (!props?.onChangeInit) return
    props?.onChangeInit(isInit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit])

  useEffect(() => {
    if (!props?.initData) return

    setLoading(true)
    const fetchRefs = props.initData.map((currentLine) => {
      const ref = currentLine.find((item) => item.name === 'reference')?.value
      return referenceApi.findByNum(ref || '', props.client)
    })
    let references: EntryFind[] = []

    Promise.all(fetchRefs)
      .then((refs) => {
        refs.forEach((ref) => {
          references.push(ref.data.entry)
        })
        setReferences(references)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initData])

  // add btn

  const onAddNewLine = () => {
    const dataClone = cloneDeep(data)
    const formatRowsClone = cloneDeep(formatRows)
    const actionClone = cloneDeep(actions)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const forceResetClone = cloneDeep(forceReset)

    const newData = calcNewLine(dataClone[dataClone.length - 1])
    if (!isFilterFournisseur) {
      const refFournisseurIndex = newData.findIndex(
        (item) => item.name === 'ref_fournisseur'
      )
      newData[refFournisseurIndex].disabled = true
    }
    dataClone.push(newData)

    for (let i = 0; i < actionClone.length; i++) {
      actionClone[i].isAllowEdit = false
      actionClone[i].isAllowDelete = false
    }
    // actionClone[actionClone.length - 1].isAllowDelete = false;
    actionClone.push({
      isAllowDelete: true,
      isFillAllRequired: false,
      isForceValid: false,
      isValid: false,
      isAllowEdit: true,
      shouldSync: false,
    })

    forceShowErrorClone.push([])
    forceShowWarningClone.push([])
    forceResetClone.push([])
    setData(dataClone)
    setFormatRows(formatRowsClone)
    setActions(actionClone)
    setForceShowError(forceShowErrorClone)
    setForceShowWarning(forceShowErrorClone)
    setForceReset(forceResetClone)
    setIsInit(false)

    focusById(`attendureference-${dataClone.length - 1}`, 500)
  }

  const calcNewLine = (oldLine: DataTableParam[]): DataTableParam[] => {
    let newData = cloneDeep(initData[0]).filter((item) => item.name !== 'uuid')
    newData.push({
      name: 'uuid',
      value: randomID('detail-line'),
    })
    return newData
  }

  const onChangeDataTable = async (
    index: number,
    nameAndValue: NameAndValue
  ) => {
    setIsInit(false)
    const dataClone = cloneDeep(data)
    let currentLine = dataClone[index]
    const forceResetClone = cloneDeep(forceReset)
    let forceShowErrorClone = cloneDeep(forceShowError)
    let currentChange = currentLine.find(
      (item) => item.name === nameAndValue.name
    )
    if (!currentChange) return

    currentChange.value = nameAndValue.value

    currentLine = currentLine.filter((item) => item.name !== nameAndValue.name)
    currentLine.push(currentChange)

    const onClearInput = (condition: boolean, index: number) => {
      if (condition) {
        currentLine[index].requiredVariant = true
        currentLine[index].value = ''
        currentLine[index].disabled = false
        currentLine[index].required = true
        currentLine[index].requiredMessage = ' '
      } else {
        currentLine[index].requiredVariant = false
        currentLine[index].value = ''
        currentLine[index].disabled = true
        currentLine[index].required = false
        currentLine[index].requiredMessage = ''
      }
    }
    const onResetInput = (name: string) => {
      const forceResetIndex = forceResetClone[index].findIndex(
        (item) => item.name === name
      )
      const forceShowWarningClone = cloneDeep(forceShowWarning)
      setForceShowWarning(forceShowWarningClone)
      forceShowWarningClone[index] = forceShowWarningClone[index].filter(
        (item) => item.name !== name
      )
      if (forceResetIndex === -1) {
        forceResetClone[index].push({ name: name, value: true })
      } else {
        forceResetClone[index][forceResetIndex].value =
          !forceResetClone[index][forceResetIndex].value
      }
      const valueIndex = currentLine.findIndex((item) => item.name === name)
      if (valueIndex !== -1) currentLine[valueIndex].value = ''
    }

    const onRemoveForceShowError = (
      name: string,
      message: string,
      index: number
    ) => {
      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => !(item.name === name && item.message === message)
      )
      setForceShowError(forceShowErrorClone)
    }

    if (currentChange.name === 'reference') {
      onResetForShowError(
        'reference',
        index,
        forceShowErrorClone,
        setForceShowError
      )
      onResetForShowError(
        'ref_fournisseur',
        index,
        forceShowErrorClone,
        setForceShowError
      )
      setLoading(true)

      const {
        refFournisseurIndex,
        libelleIndex,
        cdnIndex,
        lotIndex,
        ssccIndex,
        dluoIndex,
        qteIndex,
        refFournisseurIdIndex,
        totalPiecesIndex,
        referenceIdIndex,
        sousQteIndex,
        //referenceIndex
      } = getSomeIndex(currentLine)

      //const forceResetLotIndex = forceResetClone[index].findIndex(item => item.name === 'lot');
      onResetInput('lot')
      onResetInput('dluo')
      onResetInput('sous_qte')
      onResetInput('cdn')
      onResetInput('total_pieses')
      onResetForShowError(
        'total_pieses',
        index,
        forceShowErrorClone,
        setForceShowError
      )
      currentLine[totalPiecesIndex].validates = currentLine[
        totalPiecesIndex
      ].validates?.filter((item) => item.name !== 'greatThanSousQTE')
      currentLine[totalPiecesIndex].disabled = true

      const result = (
        await referenceApi.findByNum(nameAndValue?.value || '', props.client)
      ).data.entry
      currentLine[referenceIdIndex].value = result?.id || ''
      const referencesClone = cloneDeep(references).filter(
        (ref) => ref?.nom !== currentChange?.value
      )
      referencesClone.push(result)
      setReferences(referencesClone)
      const fournisserId =
        currentFournisseur?.fournisseur?.id ||
        currentFournisseurEdit?.fournisseur_id
      const filteredFournisser = result.ref_fournissers?.find(
        (item) => item.fournisser_id === fournisserId
      )
      if (filteredFournisser) {
        currentLine[refFournisseurIndex].value =
          filteredFournisser?.reference_fournisser || ''
        currentLine[refFournisseurIndex].disabled = !isFilterFournisseur
        currentLine[refFournisseurIdIndex].value = filteredFournisser.id
      } else {
        currentLine[refFournisseurIndex].value = ''
        currentLine[refFournisseurIndex].disabled = false
        currentLine[refFournisseurIdIndex].value = ''
      }
      currentLine[libelleIndex].value = result.libelle_long
      currentLine[cdnIndex].value = ''
      setCurrentCdns(result.conditionnement)
      currentLine[cdnIndex].options = result.conditionnement
        .filter((item) => item.att_enable)
        .map((item) => {
          return {
            label: item.nom,
            value: item.id,
          }
        })
      currentLine[cdnIndex].disabled = false

      //disable if cnd hasn't be select yet;
      const currentCdn = currentLine[cdnIndex].value
      if (!currentCdn) {
        currentLine[qteIndex].disabled = currentLine[sousQteIndex].disabled =
          true
      }

      //------------------------------------

      onClearInput(Boolean(result?.gestion), lotIndex)
      onClearInput(Boolean(result?.dluo), dluoIndex)

      currentLine[ssccIndex].disabled = false
      // currentLine[dluoIndex].disabled = false;
      currentLine[qteIndex].disabled = false
      // currentLine[sousQteIndex].disabled = false;

      //const optionsCdn = result?.conditionnement

      setLoading(false)
      setForceReset(forceResetClone)
    }

    if (currentChange.name === 'ref_fournisseur') {
      onResetForShowError(
        'ref_fournisseur',
        index,
        forceShowErrorClone,
        setForceShowError
      )
      if (isFilterFournisseur) {
        setLoading(true)
        const {
          referenceIndex,
          libelleIndex,
          cdnIndex,
          lotIndex,
          ssccIndex,
          dluoIndex,
          qteIndex,
          refFournisseurIdIndex,
          totalPiecesIndex,
        } = getSomeIndex(currentLine)

        currentLine[referenceIndex].value = nameAndValue.extraValue
        onResetInput('lot')
        onResetInput('dluo')
        onResetInput('sous_qte')
        onResetInput('cdn')
        onResetInput('total_pieses')
        currentLine[totalPiecesIndex].validates = currentLine[
          totalPiecesIndex
        ].validates?.filter((item) => item.name !== 'greatThanSousQTE')
        currentLine[totalPiecesIndex].disabled = true
        const result = (
          await referenceApi.findByNum(
            nameAndValue?.extraValue || '',
            props.client
          )
        ).data.entry
        const referencesClone = cloneDeep(references).filter(
          (ref) => ref?.nom !== currentChange?.value
        )
        referencesClone.push(result)
        setReferences(referencesClone)
        currentLine[libelleIndex].value = result.libelle_long
        currentLine[cdnIndex].value = ''
        setCurrentCdns(result.conditionnement)
        currentLine[cdnIndex].options = result.conditionnement
          .filter((item) => item.att_enable)
          .map((item) => {
            return {
              label: item.nom,
              value: item.id,
            }
          })

        currentLine[refFournisseurIdIndex].value = !isUndefined(
          result.ref_fournissers[0]
        )
          ? result.ref_fournissers[0].id
          : ''

        currentLine[cdnIndex].disabled = false
        onClearInput(Boolean(result.gestion), lotIndex)
        onClearInput(Boolean(result?.dluo), dluoIndex)

        currentLine[ssccIndex].disabled = false
        // currentLine[dluoIndex].disabled = false;
        currentLine[qteIndex].disabled = false
        // currentLine[sousQteIndex].disabled = false;
        setForceReset(forceResetClone)

        setLoading(false)
      } else {
        const { refFournisseurIndex } = getSomeIndex(currentLine)
        currentLine[refFournisseurIndex].value = nameAndValue?.value
      }
    }
    if (currentChange.name === 'dluo') {
      currentLine.push({
        name: 'dluo_format',
        value: currentChange.value,
      })

      const forceShowWarningClone = cloneDeep(forceShowWarning)

      if (
        currentChange.value &&
        (dayjs().isAfter(
          dayjs(currentChange.value, t('date-format')),
          'date'
        ) ||
          dayjs().isSame(dayjs(currentChange.value, t('date-format')), 'date'))
      ) {
        forceShowWarningClone[index].push({
          name: 'dluo',
          message: 'La DLUO est dépassée',
        })
      } else {
        forceShowWarningClone[index] = forceShowWarningClone[index].filter(
          (item) => item.name !== 'dluo'
        )
      }
      setForceShowWarning(forceShowWarningClone)
    }
    if (currentChange.name === 'sscc') {
      onRemoveForceShowError('sscc', 'SSCC déjà affecté', index)
      onRemoveForceShowError('sscc', 'Numéro déjà existant', index)

      const { qteIndex, totalPiecesIndex, referenceIndex, cdnIndex } =
        getSomeIndex(currentLine)
      if (currentChange.value) {
        currentLine[qteIndex].value = '1'
        currentLine[qteIndex].disabled = true
        currentLine[totalPiecesIndex].validates?.map((item) => {
          if (item.name === 'greatThanQTE') {
            item.validateFunction = (value) => isGreatThan(Number(value), 0)
          }
          return item
        })

        //---------------------

        const currentReference = references.find(
          (item) => item?.nom === currentLine[referenceIndex].value
        )
        let currentCDN = currentReference?.conditionnement.find(
          (e) => e.id === currentLine[cdnIndex].value
        )
        const isQTE = currentReference?.conditionnement.every((item) =>
          Boolean(item.qte)
        )
        let total_pieses_prefill: number = 0

        if (currentCDN) {
          const {
            totalPiecesIndex,
            poidsQteIndex,
            volumeQteIndex,
            volumeSousQteIndex,
            poidsSousQteIndex,
            conditionementIDIndex,
            conditionementNameIndex,
            sousQteIndex,
          } = getSomeIndex(currentLine)
          updateCDNRelateData(
            currentCDN,
            currentLine,
            volumeQteIndex,
            conditionementIDIndex,
            poidsQteIndex,
            conditionementNameIndex
          )

          const currentReference = references.find(
            (item) => item?.nom === currentLine[referenceIndex].value
          )

          if (isQTE) {
            total_pieses_prefill =
              1 *
              (currentReference?.conditionnement
                .filter(
                  (item) => Number(item.niveau) <= Number(currentCDN?.niveau)
                )
                .reduce(
                  (accumulator, currentValue) =>
                    Number(accumulator) * Number(currentValue.qte),
                  1
                ) || 1)

            // if (!currentLine[totalPiecesIndex].validates)
            //   currentLine[totalPiecesIndex].validates = []
            currentLine[totalPiecesIndex].validates?.push({
              name: 'confirm',
              message: ' ',
              order: 1,
              realtime: true,
              validateFunction: (value) =>
                isLessThan(Number(value), total_pieses_prefill + 1),
            })

            currentLine[totalPiecesIndex].validates = currentLine[
              totalPiecesIndex
            ].validates?.filter((item) => item.name !== 'greatThanSousQTE')
          }
          //}
          //Handle niveau = n - 1
          if (currentCDN.niveau && currentCDN.niveau <= 1) {
            currentLine[volumeSousQteIndex].value = '0'
            currentLine[poidsSousQteIndex].value = '0'
          } else {
            currentLine[sousQteIndex].value = String(currentCDN.qte || '-')
            const currentReference = references.find(
              (item) => item?.nom === currentLine[referenceIndex].value
            )

            let lastCurrentCDN = currentReference?.conditionnement.find(
              (e) => Number(e.niveau) === Number(currentCDN?.niveau) - 1
            )
            if (lastCurrentCDN) {
              let volumeSousQte =
                Number(lastCurrentCDN.longueur) *
                Number(lastCurrentCDN.largeur) *
                Number(lastCurrentCDN.hauteur)
              currentLine[volumeSousQteIndex].value = volumeSousQte.toString()
              currentLine[poidsSousQteIndex].value =
                lastCurrentCDN.poids_brut?.toString()
            }
          }

          currentLine[totalPiecesIndex].disabled = false
          currentLine[totalPiecesIndex].value = String(
            total_pieses_prefill || '1'
          )
        }
      } else currentLine[qteIndex].disabled = false
    }

    if (currentChange.name === 'cdn') {
      const { referenceIndex } = getSomeIndex(currentLine)

      const currentReference = references.find(
        (item) => item?.nom === currentLine[referenceIndex].value
      )
      let currentCDN = currentReference?.conditionnement.find(
        (e) => e.id === currentChange?.value
      )
      const isQTE = checkIsQteStandard(currentReference)
      let total_pieses_prefill: number = 0

      if (currentCDN) {
        const {
          totalPiecesIndex,
          poidsQteIndex,
          volumeQteIndex,
          volumeSousQteIndex,
          poidsSousQteIndex,
          conditionementIDIndex,
          conditionementNameIndex,
          sousQteIndex,
          qteIndex,
        } = getSomeIndex(currentLine)

        updateCDNRelateData(
          currentCDN,
          currentLine,
          volumeQteIndex,
          conditionementIDIndex,
          poidsQteIndex,
          conditionementNameIndex
        )

        const currentReference = references.find(
          (item) => item?.nom === currentLine[referenceIndex].value
        )

        if (isQTE) {
          const qteValue = currentLine[qteIndex]?.value || '1'
          total_pieses_prefill = getTotalPieses(
            Number(qteValue),
            currentReference,
            currentCDN
          )

          if (Number(currentLine[qteIndex]?.value) > 1) {
            currentLine[totalPiecesIndex].disabled = true
            currentLine[sousQteIndex].disabled = true
          } else {
            currentLine[totalPiecesIndex].disabled = false
            currentLine[sousQteIndex].disabled = false
          }
        } else {
          currentLine[totalPiecesIndex].disabled = false
          currentLine[sousQteIndex].value = '-'
        }
        //Handle niveau = n - 1
        if (currentCDN.niveau && currentCDN.niveau <= 1) {
          currentLine[volumeSousQteIndex].value = '0'
          currentLine[poidsSousQteIndex].value = '0'
        } else {
          currentLine[sousQteIndex].value = String(currentCDN?.qte || '-')

          const currentReference = references.find(
            (item) => item?.nom === currentLine[referenceIndex].value
          )

          let lastCurrentCDN = currentReference?.conditionnement.find(
            (e) => Number(e.niveau) === Number(currentCDN?.niveau) - 1
          )
          if (lastCurrentCDN) {
            let volumeSousQte =
              Number(lastCurrentCDN.longueur) *
              Number(lastCurrentCDN.largeur) *
              Number(lastCurrentCDN.hauteur)
            currentLine[volumeSousQteIndex].value = volumeSousQte.toString()
            currentLine[poidsSousQteIndex].value =
              lastCurrentCDN.poids_brut?.toString()
          }
        }

        currentLine[totalPiecesIndex].value = String(
          total_pieses_prefill || '1'
        )

        // set sous-qte = - when back to niveau 1
        if (currentCDN.niveau === 1) currentLine[sousQteIndex].value = '-'
      } else {
      }

      if (currentCDN?.niveau === 1) {
        const { totalPiecesIndex, sousQteIndex, qteIndex } =
          getSomeIndex(currentLine)
        currentLine[sousQteIndex].disabled = currentLine[
          totalPiecesIndex
        ].disabled = true
      }

      forceShowErrorClone[index] = forceShowErrorClone[index].filter(
        (item) => !['total_pieses', 'sous_qte'].includes(item.name)
      )
      setForceShowError(forceShowErrorClone)
    }
    if (currentChange.name === 'qte') {
      const {
        //totalPiecesIndex,
        referenceIndex,
        cdnIndex,
      } = getSomeIndex(currentLine)

      const currentReference = references.find(
        (item) => item?.nom === currentLine[referenceIndex].value
      )
      let currentCDN = currentReference?.conditionnement.find(
        (e) => e.id === currentLine[cdnIndex].value
      )
      const isQTE = currentReference?.conditionnement.every((item) =>
        Boolean(item.qte)
      )
      let total_pieses_prefill: number = 0

      if (currentCDN) {
        const {
          totalPiecesIndex,
          poidsQteIndex,
          volumeQteIndex,
          volumeSousQteIndex,
          poidsSousQteIndex,
          conditionementIDIndex,
          conditionementNameIndex,
          sousQteIndex,
        } = getSomeIndex(currentLine)
        currentLine[sousQteIndex].value = String(currentCDN.qte || '')

        updateCDNRelateData(
          currentCDN,
          currentLine,
          volumeQteIndex,
          conditionementIDIndex,
          poidsQteIndex,
          conditionementNameIndex
        )

        const currentReference = references.find(
          (item) => item?.nom === currentLine[referenceIndex].value
        )

        if (isQTE) {
          total_pieses_prefill = getTotalPieses(
            Number(currentChange.value),
            currentReference,
            currentCDN
          )

          currentLine[totalPiecesIndex].value = String(
            total_pieses_prefill || '1'
          )
          if (Number(currentChange.value) > 1) {
            currentLine[totalPiecesIndex].disabled = currentLine[
              sousQteIndex
            ].disabled = true

            forceShowErrorClone[index] = forceShowErrorClone[index].filter(
              (item) => item.name !== 'total_pieses'
            )
          } else
            currentLine[totalPiecesIndex].disabled = currentLine[
              sousQteIndex
            ].disabled = false
          currentLine[sousQteIndex].required = true
        } else currentLine[sousQteIndex].value = '-'

        //Handle niveau = n - 1
        if (currentCDN.niveau && currentCDN.niveau <= 1) {
          currentLine[volumeSousQteIndex].value = '0'
          currentLine[poidsSousQteIndex].value = '0'
          currentLine[sousQteIndex].value = '-'
          currentLine[totalPiecesIndex].value = currentChange.value
          currentLine[totalPiecesIndex].disabled = currentLine[
            sousQteIndex
          ].disabled = true
        } else {
          const currentReference = references.find(
            (item) => item?.nom === currentLine[referenceIndex].value
          )

          let lastCurrentCDN = currentReference?.conditionnement.find(
            (e) => Number(e.niveau) === Number(currentCDN?.niveau) - 1
          )
          if (lastCurrentCDN) {
            let volumeSousQte =
              Number(lastCurrentCDN.longueur) *
              Number(lastCurrentCDN.largeur) *
              Number(lastCurrentCDN.hauteur)
            currentLine[volumeSousQteIndex].value = volumeSousQte.toString()
            currentLine[poidsSousQteIndex].value =
              lastCurrentCDN.poids_brut?.toString()
          }
        }
      }
      setForceShowError(forceShowErrorClone)
    }

    if (currentChange.name === 'sous_qte') {
      const {
        sousQteIndex,
        referenceIndex,
        cdnIndex,
        volumeQteIndex,
        poidsQteIndex,
      } = getSomeIndex(currentLine)
      const sousQte = Number(currentLine[sousQteIndex].value)
      const cdn: any = currentCdns?.find(
        (item: any) => item.id === currentLine[cdnIndex].value
      )
      if (cdn?.niveau === define.MAX_CDN_LEVEL) {
        const lowerCdn: any = currentCdns?.find(
          (item: any) => item.niveau === define.MAX_CDN_LEVEL - 1
        )
        currentLine[volumeQteIndex].value = String(
          (lowerCdn.hauteur || 0) *
            (lowerCdn.largeur || 0) *
            (lowerCdn.longueur || 0) *
            Number(currentChange.value)
        )
        currentLine[poidsQteIndex].value = String(
          (lowerCdn.poids_brut || 0) * Number(currentChange.value)
        )
      }

      const currentReference = references.find(
        (item) => item?.nom === currentLine[referenceIndex].value
      )

      const {
        currentLine: newCurrentLine,
        forceShowErrorClone: newForceShowErrorClone,
      } = onChangeOnSousQte(
        index,
        sousQte,
        currentLine,
        currentReference,
        forceShowErrorClone
      )

      currentLine = newCurrentLine.filter((item) => item.value !== undefined)

      forceShowErrorClone = newForceShowErrorClone
      setForceShowError(forceShowErrorClone)
    }

    if (currentChange.name === 'total_pieses') {
      const { sousQteIndex, referenceIndex } = getSomeIndex(currentLine)

      const value = Number(currentChange.value)
      const currentReference = references.find(
        (item) => item?.nom === currentLine[referenceIndex].value
      )
      const {
        currentLine: newCurrentLine,
        forceShowErrorClone: newForceShowErrorClone,
      } = onChangeTotalPieses(
        index,
        value,
        currentLine,
        currentReference,
        forceShowErrorClone
      )
      currentLine = newCurrentLine
      forceShowErrorClone = newForceShowErrorClone
      setForceShowError(forceShowErrorClone)
    }

    dataClone[index] = currentLine
    setData(dataClone)
  }
  const onCheckInvalid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    const inValidListClone = cloneDeep(invalidList)
    if (!invalidProperty) inValidListClone[index] = []
    else {
      const indexOfProperty = inValidListClone[index].findIndex(
        (item) => item.name === invalidProperty?.name
      )
      if (indexOfProperty === -1) inValidListClone[index].push(invalidProperty)
      else inValidListClone[index][indexOfProperty] = invalidProperty
    }

    setInvalidList(inValidListClone)
  }

  const onCheckFillRequired = (index: number, list: string[]) => {
    setListNotFillRequired((oldData) => {
      let newData = cloneDeep(oldData)
      newData[index] = list
      return newData
    })
  }

  const checkDuplicate = (
    index: number,
    name: string,
    data: DataTableParam[][]
  ) => {
    const currentLine = data[index]
    const currentValue = currentLine.find((item) => item.name === name)?.value
    if (!currentValue) return false
    for (let i = 0; i < data.length; i++) {
      if (i === index) continue

      const line = data[i]
      const value = line.find((item) => item.name === name)?.value
      if (value === currentValue) return true
    }
    // ssccApi.checkSSCC(currentValue);
    return false
  }

  const pushToList = (
    list: ForceShowError[][],
    index: number,
    name: string,
    message: string
  ): ForceShowError[][] => {
    const listClone = cloneDeep(list)
    const indexError = listClone[index].findIndex((item) => item.name === name)
    if (indexError === -1) {
      listClone[index].push({ name, message })
    } else listClone[index][indexError].message = message

    return listClone
  }

  const removeFromList = (
    list: ForceShowError[][],
    index: number,
    names: string[]
  ): ForceShowError[][] => {
    const listClone = cloneDeep(list)
    listClone[index] = listClone[index].filter(
      (item) => !names.includes(item.name)
    )
    return listClone
  }

  const onCheckValidByBtn = async (index: number) => {
    let forceShowErrorClone = cloneDeep(forceShowError)
    // clear previous error if not duplicate reference
    forceShowErrorClone = removeFromList(forceShowErrorClone, index, [
      'dluo',
      'sscc',
      'reference',
    ])
    let duplicateReference = checkDuplicate(index, 'reference', data)
    let duplicateSSCC = checkDuplicate(index, 'sscc', data)
    let skipSSCC = false
    let isNotValid = false
    if (props?.mode === MODE.EDIT && props?.initData) {
      const currentData = data[index]
      const currentUuid = currentData.find(
        (item) => item.name === 'uuid'
      )?.value
      const currentSSCC = currentData.find(
        (item) => item.name === 'sscc'
      )?.value
      for (let i = 0; i < props.initData.length; i++) {
        const currentInitData = props.initData[i]
        const uuid = currentInitData.find((item) => item.name === 'uuid')?.value
        if (uuid !== currentUuid) continue

        const sscc = currentInitData.find((item) => item.name === 'sscc')?.value
        if (sscc === currentSSCC) {
          skipSSCC = true
        }

        break
      }

      // check if field need to be empty after reference update
      let invalidFields: string[] = []
      currentData.forEach((item, i) => {
        if (item.mustEmpty) {
          if (item.value && item.value?.length > 0) {
            forceShowErrorClone = pushToList(
              forceShowErrorClone,
              index,
              item.name,
              'Donnée non existante'
            )
            isNotValid = true
          } else {
            invalidFields.push(item.name)
          }
        }
      })
      forceShowErrorClone = removeFromList(
        forceShowErrorClone,
        index,
        invalidFields
      )
    }
    const currentSSCC = data[index].find((item) => item.name === 'sscc')?.value
    let exitSSCC = false
    if (!skipSSCC && currentSSCC) {
      setLoading(true)
      try {
        const checkSSCCResult = await ssccApi.checkSSCC(currentSSCC)
        setLoading(false)
        if (checkSSCCResult?.data?.messageError) exitSSCC = true
      } catch (error) {
        setLoading(false)
      }
    }
    const currentLine = data[index]

    const isStandardIndex = currentLine.findIndex(
      (item) => item.name === 'is_standard'
    )

    if (isStandardIndex === -1) {
      currentLine.push({
        name: 'is_standard',
        value: String(true),
      })
    }

    if (duplicateReference) {
      const currentReference = currentLine.find(
        (item) => item.name === 'reference'
      )?.value
      const duplicateReferenceLine = data.find((item, indexLine) => {
        if (index === indexLine) {
          return false
        }
        const refIndex = item.findIndex((i) => i.name === 'reference')
        return item[refIndex].value === currentReference
      })

      if (!currentSSCC) {
        // check if there is another item with same reference, lot, cdn, qte, total_pieses
        if (
          duplicateReferenceLine &&
          !!data.find(
            (line, i) =>
              i !== index &&
              currentLine.find((item) => item.name === 'reference')?.value ===
                line.find((item) => item.name === 'reference')?.value &&
              currentLine.find((item) => item.name === 'lot')?.value ===
                line.find((item) => item.name === 'lot')?.value &&
              (currentLine.find((item) => item.name === 'conditionement')
                ?.value === line.find((item) => item.name === 'cdn')?.value ||
                currentLine.find((item) => item.name === 'conditionement')
                  ?.value ===
                  line.find((item) => item.name === 'conditionement')?.value) &&
              parseInt(
                currentLine.find((item) => item.name === 'qte')?.value || '0'
              ) ===
                parseInt(
                  line.find((item) => item.name === 'qte')?.value || '0'
                ) &&
              parseInt(
                currentLine.find((item) => item.name === 'total_pieses')
                  ?.value || '0'
              ) ===
                parseInt(
                  line.find((item) => item.name === 'total_pieses')?.value ||
                    '0'
                ) &&
              currentLine.find((item) => item.name === 'dluo')?.value ===
                line.find((item) => item.name === 'dluo')?.value
          )
        ) {
          forceShowErrorClone = pushToList(
            forceShowErrorClone,
            index,
            'reference',
            'Référence déjà affectée'
          )
        } else {
          duplicateReference = false
        }
      } else {
        // check if there is another item with same reference, sscc
        if (
          duplicateReferenceLine &&
          !!data.find(
            (line, i) =>
              i !== index &&
              currentLine.find((item) => item.name === 'reference')?.value ===
                line.find((item) => item.name === 'reference')?.value &&
              currentLine.find((item) => item.name === 'sscc')?.value ===
                line.find((item) => item.name === 'sscc')?.value
          )
        ) {
          forceShowErrorClone = pushToList(
            forceShowErrorClone,
            index,
            'sscc',
            'Numéro déjà existant'
          )
        } else {
          duplicateReference = false
        }
      }
      if (
        // check if there is another item with same reference, lot but differ dluo
        duplicateReferenceLine &&
        !!data.find(
          (line, i) =>
            i !== index &&
            !!currentLine.find((item) => item.name === 'lot')?.value &&
            currentLine.find((item) => item.name === 'reference')?.value ===
              line.find((item) => item.name === 'reference')?.value &&
            currentLine.find((item) => item.name === 'lot')?.value ===
              line.find((item) => item.name === 'lot')?.value &&
            currentLine.find((item) => item.name === 'dluo')?.value !==
              line.find((item) => item.name === 'dluo')?.value
        )
      ) {
        duplicateReference = true
        forceShowErrorClone = pushToList(
          forceShowErrorClone,
          index,
          'dluo',
          'Veuillez vérifier la DLUO saisie'
        )
      }
    }

    if (
      !isFilterFournisseur &&
      !!data.find(
        (line, i) =>
          i !== index &&
          currentLine.find((item) => item.name === 'reference')?.value ===
            line.find((item) => item.name === 'reference')?.value &&
          currentLine.find((item) => item.name === 'ref_fournisseur')?.value !==
            line.find((item) => item.name === 'ref_fournisseur')?.value
      )
    ) {
      forceShowErrorClone = pushToList(
        forceShowErrorClone,
        index,
        'reference',
        'Référence déjà affectée'
      )
      duplicateReference = true
    }

    if (exitSSCC) {
      forceShowErrorClone = pushToList(
        forceShowErrorClone,
        index,
        'sscc',
        'SSCC déjà affecté'
      )
    }
    if (duplicateSSCC) {
      forceShowErrorClone = pushToList(
        forceShowErrorClone,
        index,
        'sscc',
        'SSCC déjà affecté'
      )
    }

    // check if fournisseur is duplicated
    let duplicateFournisseur = false
    if (
      !isFilterFournisseur &&
      !!data.find(
        (line, i) =>
          i !== index &&
          !!currentLine.find((item) => item.name === 'ref_fournisseur')
            ?.value &&
          currentLine.find((item) => item.name === 'reference')?.value !==
            line.find((item) => item.name === 'reference')?.value &&
          currentLine.find((item) => item.name === 'ref_fournisseur')?.value ===
            line.find((item) => item.name === 'ref_fournisseur')?.value
      )
    ) {
      duplicateFournisseur = true
    }
    const fournisseur = data[index].find(
      (item) => item.name === 'ref_fournisseur'
    )

    if (
      !isFilterFournisseur &&
      !duplicateFournisseur &&
      !fournisseur?.disabled
    ) {
      setLoading(true)
      try {
        const checkFournisseurResult =
          await referenceApi.checkFournisseurExisted({
            client_code_nom:
              localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
            fournisser_id:
              currentFournisseur?.fournisseur?.id ||
              currentFournisseurEdit?.fournisseur_id ||
              '',
            reference_fournisser: fournisseur?.value || '',
          })
        if (checkFournisseurResult) {
          duplicateFournisseur = true
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    if (!isFilterFournisseur && duplicateFournisseur) {
      forceShowErrorClone = pushToList(
        forceShowErrorClone,
        index,
        'ref_fournisseur',
        'Numéro déjà affecté'
      )
    }

    if (
      exitSSCC ||
      duplicateSSCC ||
      duplicateReference ||
      (!isFilterFournisseur && duplicateFournisseur) ||
      isNotValid
    ) {
      setForceShowError(forceShowErrorClone)
      return
    }

    //check totalpices >= qte and sous qte
    const referenceIndex = data[index].findIndex(
      (item) => item.name === 'reference'
    )
    const currentReference = references.find(
      (item) => item?.nom === currentLine[referenceIndex].value
    )
    const isQTE = currentReference?.conditionnement.every((item) =>
      Boolean(item.qte)
    )

    if (isQTE) {
      const qte = data[index].find((item) => item.name === 'qte')?.value
      let sousQte = data[index].find((item) => item.name === 'sous_qte')?.value
      if (sousQte === '-') sousQte = '1'
      const total_pieses = data[index].find(
        (item) => item.name === 'total_pieses'
      )?.value
      if (
        Number(total_pieses) < Number(qte) ||
        Number(total_pieses) < Number(sousQte)
      ) {
        forceShowErrorClone = pushToList(
          forceShowErrorClone,
          index,
          'total_pieses',
          'Veuillez vérifier la valeur saisie'
        )
        setForceShowError(forceShowErrorClone)

        return
      }
    }
    //---------

    // check not divisible total pieses

    const optionsCdn = currentReference?.conditionnement
    const cdnIndex = getIndexes('cdn', currentLine)
    const currentCDN = optionsCdn?.find(
      (item) => item.id === currentLine[cdnIndex]?.value
    )
    if (
      optionsCdn &&
      optionsCdn?.length > 1 &&
      currentCDN?.niveau &&
      currentCDN?.niveau > 1 &&
      isQTE
    ) {
      const cdn2 = optionsCdn[1]
      const qte2 = Number(cdn2.qte)

      const totalPiecesIndex = getIndexes('total_pieses', data[index])

      const currentTotalPiece = Number(currentLine[totalPiecesIndex].value)
      if (currentTotalPiece % qte2 !== 0) {
        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) => item.name !== 'exceedingTotalPiece'
        )
        forceShowErrorClone[index].push({
          name: 'total_pieses',
          message: 'Veuillez vérifier la valeur saisie',
        })
        setForceShowError(forceShowErrorClone)
        return
      }
    }

    //------------------

    const forceShowWarningClone = cloneDeep(forceShowWarning)
    forceShowWarningClone[index] = []

    const actionsClone = cloneDeep(actions)
    let disableLinesClone = cloneDeep(disableLines)
    actionsClone[index].isForceValid = true

    for (let i = 0; i < actionsClone.length; i++) {
      actionsClone[i].isAllowEdit = true
      actionsClone[i].isAllowDelete = true
    }
    actionsClone[index].shouldSync = false
    disableLinesClone.push(index)
    disableLinesClone = uniqBy(disableLinesClone, (e) => e)
    let dataClone = cloneDeep(data)
    const refChangeIndex = dataClone[index].findIndex(
      (item) => item.name === 'ref_change'
    )
    if (dataClone[index][refChangeIndex]) {
      // @ts-ignore
      dataClone[index][refChangeIndex].value = false
    }
    setData(dataClone)
    setForceShowError(forceShowErrorClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setForceShowWarning(forceShowWarningClone)
    setIsInit(false)

    // focus on edit button after valid success
    focusById(`attenduicon-edit-${index}`, 500)

    // save unlink reference and ref fournisseur to link when submit
    const needLinkReferenceList =
      form.getFieldValue('needLinkReferenceList') || []
    if (!isFilterFournisseur) {
      const reference_fournisser = data[index].find(
        (item) => item.name === 'ref_fournisseur'
      )
      if (!reference_fournisser?.disabled) {
        const ref_nom = data[index].find(
          (item) => item.name === 'reference'
        )?.value
        form.setFieldValue('needLinkReferenceList', [
          ...needLinkReferenceList,
          { ref_nom, reference_fournisser: reference_fournisser?.value },
        ])
      }
    }
  }

  async function setDisableRefFourniseur(index: number) {
    const dataClone = cloneDeep(data)
    let currentLine = dataClone[index]
    const refFournisseurIndex = currentLine.findIndex(
      (item) => item.name === 'ref_fournisseur'
    )

    const referenceIndex = currentLine.findIndex(
      (item) => item.name === 'reference'
    )
    if (dataClone[index][referenceIndex]?.value) {
      try {
        setLoading(true)
        const result = (
          await referenceApi.findByNum(
            dataClone[index][referenceIndex]?.value || '',
            props.client
          )
        ).data.entry
        const fournisserId =
          currentFournisseur?.fournisseur?.id ||
          currentFournisseurEdit?.fournisseur_id
        const filteredFournisser = result.ref_fournissers?.find(
          (item) => item.fournisser_id === fournisserId
        )
        currentLine[refFournisseurIndex].disabled = !!filteredFournisser
        dataClone[index] = currentLine
        setData(dataClone)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
  }

  async function setCdnOptions(index: number, dataClone: DataTableParam[][]) {
    let currentLine = dataClone[index]
    const referenceIndex = currentLine.findIndex(
      (item) => item.name === 'reference'
    )
    if (dataClone[index][referenceIndex]?.value) {
      try {
        setLoading(true)
        const result = (
          await referenceApi.findByNum(
            dataClone[index][referenceIndex]?.value || '',
            props.client
          )
        ).data.entry
        const cdnIndex = currentLine.findIndex((item) => item.name === 'cdn')
        setCurrentCdns(result.conditionnement)
        currentLine[cdnIndex].options = result.conditionnement
          .filter((item) => item.att_enable)
          .map((item) => {
            return {
              label: item.nom,
              value: item.id,
            }
          })
        const isQTE = result.conditionnement.every((item) => Boolean(item.qte))
        const totalPiecesIndex = dataClone[index].findIndex(
          (item) => item.name === 'total_pieses'
        )

        if (isQTE) {
          const qteValue = Number(
            data[index].find((item) => item.name === 'qte')?.value
          )
          if (qteValue > 1) dataClone[index][totalPiecesIndex].disabled = true
          else dataClone[index][totalPiecesIndex].disabled = false
        } else dataClone[index][totalPiecesIndex].disabled = false

        const { sousQteIndex, qteIndex } = getSomeIndex(currentLine)
        const qte = Number(currentLine[qteIndex].value)
        const { totalPieses: tDis, sousQte: sDis } =
          getStateOfTotalPiesesAndSousQte(qte, currentLine, references)
        currentLine[totalPiecesIndex].disabled = tDis
        currentLine[sousQteIndex].disabled = sDis
        dataClone[index] = currentLine
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
  }

  const onAllowEdit = async (
    index: number,
    dataClone: DataTableParam[][],
    { preventSetCdn, isEdit }: { preventSetCdn: boolean; isEdit: boolean }
  ) => {
    let disableLinesClone = cloneDeep(disableLines)
    let actionsClone = cloneDeep(actions)
    const currentLine = dataClone[index]
    const { referenceIndex, sousQteIndex, totalPiecesIndex } =
      getSomeIndex(currentLine)

    disableLinesClone = disableLinesClone.filter((item) => item !== index)
    actionsClone[index].isForceValid = false
    actionsClone[index].isAllowEdit = true
    for (let i = 0; i < actionsClone.length; i++) {
      if (i !== index) {
        actionsClone[i].isAllowEdit = false
        actionsClone[i].isAllowDelete = false
      }
    }

    const currentReference = references.find(
      (item) => item?.nom === currentLine[referenceIndex].value
    )
    const cdnId = currentLine.find((item) => item.name === 'cdn')?.value
    const currentCdn = currentReference?.conditionnement.find(
      (item) => item.id === cdnId
    )
    if (currentCdn?.niveau === 1) {
      currentLine[sousQteIndex].disabled = currentLine[
        totalPiecesIndex
      ].disabled = true
    } else
      currentLine[sousQteIndex].disabled = currentLine[
        totalPiecesIndex
      ].disabled = false
    dataClone[index] = currentLine

    setDisableLines(disableLinesClone)
    if (!preventSetCdn) {
      await setCdnOptions(index, dataClone)
    } else {
      if (!isEdit) {
        actionsClone[index].isValid = false
      }
    }
    setActions(actionsClone)
    if (!isFilterFournisseur) {
      setDisableRefFourniseur(index)
    }

    //focus on valid button
    focusById(`attenduicon-valid-${index}`, 500)
    // setData(dataClone)
  }

  const onDeleteLine = (index: number) => {
    let dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const resetFlagClone = cloneDeep(resetFlags)
    let formatRowsClone = cloneDeep(formatRows)
    let actionsClone = cloneDeep(actions)
    let disabledLinesClone = cloneDeep(disableLines)
    let invalidListClone = cloneDeep(invalidList)
    let listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    let forceResetClone = cloneDeep(forceReset)

    if (data.length === 1 && index === 0) {
      dataClone[0] = firstLineData
      resetFlagClone[0] = !resetFlagClone[0]
      forceResetClone = [[]]
      actionsClone[0] = {
        isAllowDelete: true,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
        isAllowEdit: true,
        shouldSync: false,
      }
      setIsInit(true)
    } else {
      dataClone.splice(index, 1)
      forceResetClone.splice(index, 1)
      actionsClone.splice(index, 1)

      for (let i = 0; i < actionsClone.length; i++) {
        actionsClone[i].isAllowEdit = true
        actionsClone[i].isAllowDelete = true
      }

      forceShowErrorClone.splice(index, 1)
      forceShowWarningClone.splice(index, 1)
      formatRowsClone.splice(index, 1)
      invalidListClone.splice(index, 1)
      listNotFillRequiredClone.splice(index, 1)
    }
    forceShowErrorClone[index] = []
    disabledLinesClone = disabledLinesClone.filter((item) => item !== index)
    disabledLinesClone = disabledLinesClone.map((item) =>
      item > index ? item - 1 : item
    )
    dataClone.map((items) =>
      items.map((i) => {
        if (i.name === 'sscc' && !i.value) {
          i.value = ''
        }
        return items
      })
    )

    const currentRef = data[index].find(
      (item) => item.name === 'reference'
    )?.value
    let referencesClone = cloneDeep(references)
    referencesClone = (referencesClone || []).filter(
      (item) => item?.nom !== currentRef
    )
    setReferences(referencesClone)
    setData(dataClone)
    setForceShowError(forceShowErrorClone)
    setResetFlags(resetFlagClone)
    setDisableLines(disabledLinesClone)
    setActions(actionsClone)
    setFormatRows(formatRowsClone)
    setInvalidList(invalidListClone)
    setListNotFillRequired(listNotFillRequiredClone)
    setForceReset(forceResetClone)
    if (data.length === 1) setIsInit(true)
    else setIsInit(false)
    // delete unlink ref and fournisseur
    const needLinkReferenceList =
      form.getFieldValue('needLinkReferenceList') || []
    const reference_fournisser = data[index].find(
      (item) => item.name === 'ref_fournisseur'
    )?.value
    const ref_nom = data[index].find((item) => item.name === 'reference')?.value
    form.setFieldValue(
      'needLinkReferenceList',
      needLinkReferenceList.filter(
        (i: { ref_nom: string; reference_fournisser: string }) =>
          i.reference_fournisser !== reference_fournisser &&
          i.ref_nom !== ref_nom
      )
    )
  }

  const onConfirm = (index: number, name: string) => {
    const dataClone = cloneDeep(data)
    const currentLine = dataClone[index]
    const indexOfProperty = currentLine.findIndex((item) => item.name === name)
    if (indexOfProperty === -1) return
    currentLine[indexOfProperty].confirmMode = false
    currentLine[indexOfProperty].openConfirm = false
    dataClone[index] = currentLine
    setData(dataClone)
  }

  useEffect(() => {
    if (!props?.onChangeData) return
    const newData = cloneDeep(data)
    // for (let i = 0; i < newData.length; i++) {
    //   const sousQteIndex = newData[i].findIndex(
    //     (item) => item.name === 'sous_qte'
    //   )
    //   if (sousQteIndex !== -1) {
    //     if (newData[i][sousQteIndex].value === '-')
    //       newData[i][sousQteIndex].value = '1'
    //   }
    // }
    props.onChangeData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!props?.onCheckValid) return
    const isAllValid = actions.every(
      (item) =>
        item.isAllowEdit &&
        item.isForceValid &&
        item.isFillAllRequired &&
        item.isValid
    )
    props.onCheckValid(isAllValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  useEffect(() => {
    if (!props?.initData) return

    let disableLinesClone: number[] = props.initData.map((_, idx) => idx)
    setDisableLines(disableLinesClone)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currentFournisseur?.fournisseur,
    props?.initData,
    // currentFournisseurEdit?.id,
  ])

  useEffect(() => {
    const mode = props?.mode
    if (mode === MODE.CREATE) {
      if (!currentFournisseur.fournisseur) {
        setBlockTable(true)
      } else setBlockTable(false)

      return
    }
    if (!currentFournisseurEdit.code_nom) {
      setBlockTable(true)
    } else setBlockTable(false)
  }, [props?.mode, currentFournisseur, currentFournisseurEdit])

  useEffect(() => {
    if (!currentFournisseur?.fournisseur) return
    if (!prevCurrentFournisseur?.fournisseur?.id) return
    if (
      currentFournisseur?.fournisseur?.id ===
      prevCurrentFournisseur?.fournisseur?.id
    )
      return
    let forceResetClone = cloneDeep(forceReset)
    let firstLine: ResetProperty[] = header.map((item) => {
      return {
        name: item.name || '',
        value: true,
      }
    })
    forceResetClone[0] = firstLine
    let firstLineInverse = firstLine.map((item) => {
      return {
        name: item.name || '',
        value: false,
      }
    })

    setForceReset(forceResetClone)
    setTimeout(() => {
      forceResetClone[0] = firstLineInverse
      setForceReset(forceResetClone)
    }, 100)

    let newInitData = initData.map((line) =>
      line.map((item) => {
        if (item.options) item.options = []
        if (item.name === 'ref_fournisseur' && !isFilterFournisseur) {
          item.disabled = true
        }
        return item
      })
    )

    setData(newInitData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur?.fournisseur, isFilterFournisseur])

  useEffect(() => {
    if (currentFournisseur?.fournisseur?.id || currentFournisseurEdit) {
      const headerClone = cloneDeep(header)
      const referenceIndex = headerClone.findIndex(
        (item) => item.name === 'reference'
      )
      const refFournisseurIndex = headerClone.findIndex(
        (item) => item.name === 'ref_fournisseur'
      )
      headerClone[referenceIndex].cInputProps.onSearch = (keyword: string) =>
        keyword.length >= 1
          ? referenceApi
              .searchReferenceWithFournisser(
                keyword,
                isFilterFournisseur
                  ? currentFournisseur?.fournisseur?.id ||
                      currentFournisseurEdit?.fournisseur_id
                  : undefined,
                props.client
              )
              .then((res) => res?.data?.entry || [])
              .then((res) =>
                res.map((item) => {
                  let option: OptionType = {
                    label: item.ref_nom,
                    value: item.ref_nom,
                    extraValue: item.reference_fournisser,
                    disabled: item?.blocage_entree || !item?.att_enable,
                  }
                  return option
                })
              )
          : defaultPromise
      headerClone[refFournisseurIndex].cInputProps.onSearch = (
        keyword: string
      ) =>
        keyword.length >= 1
          ? referenceApi
              .searchRefFournisseurWithFournisser(
                keyword,
                currentFournisseur?.fournisseur?.id ||
                  currentFournisseurEdit?.fournisseur_id ||
                  '',
                props.client
              )
              .then((res) => res?.data?.entry || [])
              .then((res) =>
                res.map((item) => {
                  let option: OptionType = {
                    label: item.reference_fournisser,
                    value: item.reference_fournisser,
                    extraValue: item.ref_nom,
                    disabled: item?.blocage_entree || !item?.att_enable,
                  }
                  return option
                })
              )
          : defaultPromise
      setHeader(headerClone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentFournisseur?.fournisseur?.id,
    currentFournisseurEdit?.fournisseur_id,
    isFilterFournisseur,
  ])

  useEffect(() => {
    if (!props?.onCheckValid) return
    const isAllValid = actions.every(
      (item) =>
        item.isAllowEdit &&
        item.isForceValid &&
        item.isFillAllRequired &&
        item.isValid
    )

    props.onCheckValid(isAllValid || isInit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  useEffect(() => {
    let isAtLeastOneLineValid = actions.some(
      (item) =>
        // item.isAllowDelete &&
        item.isFillAllRequired && item.isForceValid && item.isValid
    )
    dispatch(
      changeDetailSection({ validAtLeastOneLine: isAtLeastOneLineValid })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  useEffect(() => {
    if (!props?.initData) return
    const actionsClone = []
    const forceShowErrorClone = []
    const forceResetClone = []
    const invalidListClone = []
    const forceShowWarningClone = []

    if (props?.initData.length === 0) {
      // actionsClone.push(action)
      forceShowErrorClone.push([])
      forceResetClone.push([])
      invalidListClone.push([])
      forceShowWarningClone.push([])
    } else {
      for (let i = 0; i < props?.initData.length || 0; i++) {
        const refChange = props?.initData[i].find(
          (item) => item.name === 'ref_change'
        )
        const action: ActionParam = {
          isAllowDelete: true,
          isAllowEdit: true,
          isForceValid: true,
          isFillAllRequired: true,
          isValid: true,
          shouldSync: Boolean(refChange?.value),
        }
        actionsClone.push(action)
        forceShowErrorClone.push([])
        forceResetClone.push([])
        invalidListClone.push([])
        forceShowWarningClone.push([])
      }
    }
    if (props?.initData.length > 0) {
      // forceShowErrorClone.pop()
      // invalidListClone.pop()
      // forceShowWarningClone.pop()
      // forceResetClone.pop()
    }

    setActions(actionsClone)
    setForceShowError(forceShowErrorClone)
    setForceReset(forceResetClone)
    setInvalidList(invalidListClone)
    setForceShowWarning(forceShowWarningClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initData])

  useEffect(() => {
    if (
      props?.mode === MODE.EDIT &&
      !isEmpty(props?.initData) &&
      isInit &&
      references.length > 0
    ) {
      let initDataClone = cloneDeep(props?.initData) || []
      for (let i = 0; i < initDataClone.length; i++) {
        const currentLine = initDataClone[i]
        const totalPiecesIndex = currentLine.findIndex(
          (item) => item.name === 'total_pieses'
        )
        const sousQteIndex = currentLine.findIndex(
          (item) => item.name === 'sous_qte'
        )
        const qteIndex = currentLine.findIndex((item) => item.name === 'qte')
        if (totalPiecesIndex === -1 || sousQteIndex === -1) continue

        // const sousQte = Number(currentLine[sousQteIndex].value)
        // initDataClone[i][totalPiecesIndex].validates = [
        //   {
        //     name: 'greatThanSousQTE',
        //     message: 'Veuillez vérifier la valeur saisie',
        //     order: 2,
        //     realtime: true,
        //     validateFunction: (value) =>
        //       isGreatThan(Number(value), sousQte - 1),
        //   },
        // ]

        const cdnIndex = currentLine.findIndex((item) => item.name === 'cdn')
        const currentRef = currentLine.find((item) => item.name === 'reference')
        const currentOptions = references.find(
          (item) => item?.nom === currentRef?.value
        )?.conditionnement

        if (cdnIndex !== -1) {
          initDataClone[i][cdnIndex].options = currentOptions?.map((item) => {
            return {
              label: item.nom,
              value: item.id,
            }
          })
        }

        const isQte = references
          .find((item) => item?.nom === currentRef?.value)
          ?.conditionnement.every((item) => item.qte === 1)

        if (qteIndex !== -1) {
          if (isQte && Number(currentLine[qteIndex].value) > 1) {
            initDataClone[i][totalPiecesIndex].disabled = true
          } else initDataClone[i][totalPiecesIndex].disabled = false
        }
      }
      setData(initDataClone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initData, props?.mode, references])

  useEffect(() => {
    if (props?.mode === MODE.EDIT && isEmpty(props?.initData)) {
      setData(initData)
    }
  }, [props?.initData, props?.mode])

  useEffect(() => {
    const currentId = currentFournisseurEdit?.fournisseur_id
    const preId = prevCurrentFournisseurEdit?.fournisseur_id
    if (preId !== currentId && preId) {
      setData(initData)
    }
  }, [
    currentFournisseurEdit?.fournisseur_id,
    prevCurrentFournisseurEdit?.fournisseur_id,
  ])

  function changeFilterFournisser(val: boolean) {
    setFilterFournisseur(val)
    const headerClone = cloneDeep(header)
    const refFournisseurIndex = headerClone.findIndex(
      (item) => item.name === 'ref_fournisseur'
    )
    // reset data in editing line when change filter fournisseur
    let cloneData = [...data]
    for (let i = 0; i < cloneData.length; i++) {
      if (!disableLines.includes(i)) {
        cloneData[i] = cloneDeep(initData[0])
        const index = cloneData[i].findIndex(
          (e) => e.name === 'ref_fournisseur'
        )
        cloneData[i][index].disabled = !val
      }
    }
    setData(cloneData)

    // reset list options of selection reference
    let forceShowErrorClone = cloneDeep(forceShowError)
    forceShowErrorClone[data?.length - 1] = []
    setForceShowError(forceShowErrorClone)
    let forceResetClone = cloneDeep(forceReset)
    forceResetClone[data?.length - 1] = [
      {
        name: 'reference',
        value: !forceResetClone[data?.length - 1].find(
          (item) => item.name === 'reference'
        )?.value,
      },
    ]
    setForceReset(forceResetClone)
    // change type of ref fournisseur input
    headerClone[refFournisseurIndex].cInputProps = val
      ? {
          ...cInputProps,
          validates: [],
          requiredMessage: '',
          type: [InputType.C_SEARCH],
          placeHolder: '-',
        }
      : {
          ...cInputProps,
          requiredMessage: ' ',
          className: cInputProps.className + ' w-40',
          placeHolder: '-',
          type: [InputType.ALL_TEXT],
          value: '',
          disabled: true,
          maxLength: 20,
          format: removeTonesUpperCase,
        }
    setHeader(headerClone)
  }

  function calculateTotalPieces(
    currentReference: EntryFind,
    currentQte: string,
    currentCDN: any
  ) {
    const total_pieses_prefill =
      Number(currentQte) *
      (currentReference?.conditionnement
        .filter((item) => Number(item.niveau) <= Number(currentCDN?.niveau))
        .reduce(
          (accumulator, currentValue) =>
            Number(accumulator) * Number(currentValue.qte),
          1
        ) || 1)
    return total_pieses_prefill
  }

  async function syncReference(index: number) {
    let cloneData = [...data]
    const actionsClone = cloneDeep(actions)
    let currentLine = cloneData[index]
    const referenceIndex = currentLine.findIndex(
      (item) => item.name === 'reference'
    )
    const reference = currentLine[referenceIndex]?.value
    if (!reference) return
    try {
      const newReference = await referenceApi.findByNum(reference, props.client)
      const newReferenceData = newReference.data.entry
      let forceShowErrorClone = cloneDeep(forceShowError)

      const {
        conditionnement,
        dluo,
        gestion,
        blocage_entree,
        libelle_long,
        ref_fournissers,
      } = newReferenceData
      const conditionementValue = currentLine.find(
        (item) => item.name === 'conditionement_id'
      )?.value
      const referenceIndex = currentLine.findIndex(
        (item) => item.name === 'reference'
      )
      const cdnIndex = currentLine.findIndex((item) => item.name === 'cdn')
      const cdnValue = currentLine[cdnIndex]?.value
      const qteValue = currentLine.find((item) => item.name === 'qte')?.value
      const lotIndex = currentLine.findIndex((item) => item.name === 'lot')
      const lotValue = currentLine[lotIndex]?.value
      const dluoIndex = currentLine.findIndex((item) => item.name === 'dluo')
      const dluoValue = currentLine[dluoIndex]?.value
      const libelleIndex = currentLine.findIndex(
        (item) => item.name === 'libelle'
      )
      const libelleValue = currentLine[libelleIndex]?.value
      const refFournisseurIndex = currentLine.findIndex(
        (item) => item.name === 'ref_fournisseur'
      )

      const refFournisseurValue = currentLine[refFournisseurIndex]?.value
      const totalPieceValue = currentLine.find(
        (item) => item.name === 'total_pieses'
      )?.value
      const refFournisseurIdIndex = currentLine.findIndex(
        (item) => item.name === 'ref_fournisser_id'
      )
      const refFournisseurIdValue = currentLine[refFournisseurIdIndex]?.value
      const conditionnementValue = conditionnement.find(
        (item) => item.id === cdnValue
      )
      const isQTE = conditionnement.every((item) => Boolean(item.qte))
      const isCdnChanged = !conditionnement.find(
        (i) => i.id === conditionementValue && i.att_enable === true
      )
      const ref_fournisser_value = ref_fournissers.find(
        (item) => item.id === refFournisseurIdValue
      )?.reference_fournisser
      if (ref_fournisser_value !== refFournisseurValue) {
        currentLine[refFournisseurIndex].value = ref_fournisser_value
      }
      if (libelleValue !== libelle_long) {
        currentLine[libelleIndex].value = libelle_long
      }
      setCurrentCdns(conditionnement)
      currentLine[cdnIndex].options = conditionnement
        .filter((item) => item.att_enable)
        .map((item) => {
          return {
            label: item.nom,
            value: item.id,
          }
        })
      if (!isCdnChanged) {
        //no need to choose cdn again
        let newTotalPieces = 0
        const totalPieceIndex = currentLine.findIndex(
          (item) => item.name === 'total_pieses'
        )
        if (isQTE) {
          newTotalPieces = calculateTotalPieces(
            // @ts-ignore
            newReferenceData,
            qteValue || '0',
            conditionnementValue
          )
          const sousQteIndex = currentLine.findIndex(
            (item) => item.name === 'sous_qte'
          )
          currentLine[sousQteIndex].value =
            conditionnementValue?.qte?.toString()
          if (Number(qteValue) > 1) {
            const totalPiecesIndex = currentLine.findIndex(
              (item) => item.name === 'total_pieses'
            )
            currentLine[totalPiecesIndex].disabled = true
          }
        } else {
          newTotalPieces = Number(totalPieceValue)
        }
        currentLine[totalPieceIndex].value =
          newTotalPieces === 0 ? '' : newTotalPieces?.toString()
      }

      const isDluoChange = Boolean(dluo) !== Boolean(dluoValue)
      const isLotChange = Boolean(gestion) !== Boolean(lotValue)
      if (isCdnChanged || isDluoChange || isLotChange || blocage_entree) {
        onAllowEdit(index, cloneData, { preventSetCdn: true, isEdit: false })
        if (isCdnChanged) {
          currentLine[cdnIndex].value = ''
          if (!conditionnement.find((i) => i.att_enable === true)) {
            forceShowErrorClone = pushToList(
              forceShowErrorClone,
              index,
              'cdn',
              'Veuillez choisir une autre référence'
            )
          }
        }
        if (isDluoChange) {
          currentLine[dluoIndex].disabled = false
          if (!dluo) {
            forceShowErrorClone = pushToList(
              forceShowErrorClone,
              index,
              'dluo',
              'Donnée non existante'
            )
            currentLine[dluoIndex].requiredVariant = false
            currentLine[dluoIndex].required = false
            currentLine[dluoIndex].mustEmpty = true
          } else {
            currentLine[dluoIndex].mustEmpty = false
            currentLine[dluoIndex].requiredVariant = true
            currentLine[dluoIndex].required = true
          }
        }
        if (isLotChange) {
          currentLine[lotIndex].disabled = false
          if (!gestion) {
            forceShowErrorClone = pushToList(
              forceShowErrorClone,
              index,
              'lot',
              'Donnée non existante'
            )
            currentLine[lotIndex].requiredVariant = false
            currentLine[lotIndex].required = false
            currentLine[lotIndex].mustEmpty = true
          } else {
            currentLine[lotIndex].mustEmpty = false
            currentLine[lotIndex].requiredVariant = true
            currentLine[lotIndex].required = true
          }
        }
        if (blocage_entree) {
          currentLine[referenceIndex].value = ''
          forceShowErrorClone = pushToList(
            forceShowErrorClone,
            index,
            'reference',
            'Veuillez choisir une autre référence'
          )
        }
      } else {
        //---------
        const refChangeIndex = currentLine.findIndex(
          (item) => item.name === 'ref_change'
        )
        // @ts-ignore
        currentLine[refChangeIndex].value = false

        let disableLinesClone = cloneDeep(disableLines)
        actionsClone[index].isForceValid = true
        actionsClone[index].isAllowEdit = true
        actionsClone[index].isAllowDelete = true
        actionsClone[index].isFillAllRequired = true
        actionsClone[index].shouldSync = false
        disableLinesClone.push(index)

        disableLinesClone = uniqBy(disableLinesClone, (e) => e)
        setDisableLines(disableLinesClone)
        setIsInit(false)
        setActions(actionsClone)
      }

      const currentRefObj = references.find(
        (item) => item?.nom === currentLine[referenceIndex].value
      )
      if (currentRefObj) {
        const currentCdn = currentRefObj.conditionnement.find(
          (item) => item.id === currentLine[cdnIndex].value
        )
        const { sousQteIndex, totalPiecesIndex } = getSomeIndex(currentLine)
        if (currentCdn?.niveau === 1) {
          cloneData[index][sousQteIndex].disabled = cloneData[index][
            totalPiecesIndex
          ].disabled = true
        } else
          cloneData[index][sousQteIndex].disabled = cloneData[index][
            totalPiecesIndex
          ].disabled = false
      }
      setForceShowError(forceShowErrorClone)
      setData(cloneData)
    } catch (e) {
      console.log('error: ', e)
    }
  }

  async function clickEditButton(index: number) {
    const refChange = props?.initData?.[index]?.find(
      (item) => item.name === 'ref_change'
    )?.value
    const dataClone = cloneDeep(data)
    await onAllowEdit(index, dataClone, {
      preventSetCdn: Boolean(refChange),
      isEdit: true,
    })
    setData(dataClone)
  }

  const onNotConfirm = (index: number, name: string) => {
    const refId = data[index].find((item) => item.name === 'reference')?.value
    const currentReference = references.find((item) => item?.nom === refId)
    const currentCdnId = data[index].find((item) => item.name === 'cdn')?.value
    const currentSousQte = currentReference?.conditionnement.find(
      (item) => item.id === currentCdnId
    )?.qte
    const currentCDN = currentReference?.conditionnement.find(
      (item) => item.id === currentCdnId
    )
    const dataClone = cloneDeep(data)
    const sousQteIndex = dataClone[index].findIndex(
      (item) => item.name === 'sous_qte'
    )
    const totalPiesesIndex = dataClone[index].findIndex(
      (item) => item.name === 'total_pieses'
    )

    const total_pieses_prefill =
      1 *
      (currentReference?.conditionnement
        .filter((item) => Number(item.niveau) <= Number(currentCDN?.niveau))
        .reduce(
          (accumulator, currentValue) =>
            Number(accumulator) * Number(currentValue.qte),
          1
        ) || 1)

    dataClone[index][sousQteIndex].value = String(currentSousQte)
    dataClone[index][totalPiesesIndex].value = String(total_pieses_prefill)

    setData(dataClone)
  }

  return (
    <SectionLayout
      title="Détail"
      action={
        <Action
          onClickPlusBtn={onAddNewLine}
          isDisablePlusBtn={
            !(
              actions[actions.length - 1]?.isForceValid &&
              actions[actions.length - 1]?.isValid
            ) ||
            data.length >= 99 ||
            disableLines.length !== data.length
          }
          isDisableFilter={false}
          isFilter={isFilterFournisseur}
          onToggleFilterFournisseur={changeFilterFournisser}
          onChangePhotos={props.onChangePhoto}
          photoValue={props.valuePhoto}
          detailLength={data.length}
        />
      }
      alert={props?.alert}
    >
      <div className="overflow-y-hidden pb-2 detail-scroll box-input-font-size-bigger">
        <Spin spinning={loading}>
          <ITable
            name="attendu"
            blockTable={blockTable}
            resetFlags={resetFlags}
            formatCols={formatCols}
            formatRows={formatRows}
            header={header}
            data={data}
            actions={actions}
            disabledLines={disableLines}
            onChangeDataTable={onChangeDataTable}
            onCheckInvalid={onCheckInvalid}
            onCheckFillRequired={onCheckFillRequired}
            onConfirm={onConfirm}
            onNotConfirm={onNotConfirm}
            forceShowError={forceShowError}
            forceShowWarning={forceShowWarning}
            hideCols={hideCols}
            observeAndTransform={observeAndTransform}
            forceReset={forceReset}
            actionNode={(
              index: number,
              actionParam?: ActionParam
            ): ReactNode => {
              const {
                isValid = false,
                isFillAllRequired = false,
                isAllowDelete = true,
                isForceValid = false,
                isAllowEdit = true,
                shouldSync = false,
              } = actionParam || {
                isValid: false,
                isFillAllRequired: false,
                isAllowDelete: true,
                isForceValid: false,
                isAllowEdit: true,
                shouldSync: false,
              }
              return (
                <div>
                  <Row gutter={8} align="middle">
                    <Col span={12}>
                      {(!isFillAllRequired || !isValid) && (
                        <i className="icon-valid-disable"></i>
                      )}
                      {isFillAllRequired && isValid && !isForceValid && (
                        <i
                          onClick={() => onCheckValidByBtn(index)}
                          className="icon-valid focus:rounded-2xl"
                          data-previous-id={`attendutotal_pieses-${index}`}
                          data-next-id={`attenduicon-delete-${index}`}
                          id={`attenduicon-valid-${index}`}
                          tabIndex={0}
                        />
                      )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        isAllowEdit && (
                          <>
                            {shouldSync ? (
                              <i
                                className="icon-sync"
                                onClick={() => syncReference(index)}
                              />
                            ) : (
                              <i
                                className="icon-edit"
                                id={`attenduicon-edit-${index}`}
                                data-previous-id={`attendutotal_pieses-${index}, attenduicon-delete-${
                                  index - 1
                                }, ${attenduKey['Photos']}`}
                                data-next-id={`attenduicon-delete-${index}`}
                                tabIndex={0}
                                onClick={() => clickEditButton(index)}
                              />
                            )}
                          </>
                        )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        !isAllowEdit && (
                          <>
                            {shouldSync ? (
                              <i className="icon-sync-disable" />
                            ) : (
                              <i className="icon-edit-disable" />
                            )}
                          </>
                        )}
                    </Col>
                    <Col span={12}>
                      {isAllowDelete && (
                        <Popconfirm
                          icon={null}
                          title="Êtes-vous sûr de vouloir supprimer cet élément?"
                          description=""
                          okText="Supprimer"
                          okButtonProps={{ className: 'ok-btn' }}
                          cancelButtonProps={{ className: 'cancel-btn' }}
                          cancelText="Annuler"
                          onConfirm={() => onDeleteLine(index)}
                          onCancel={() =>
                            focusById(`attenduicon-delete-${index}`)
                          }
                        >
                          <i
                            className="icon-delete focus:rounded-2xl"
                            tabIndex={0}
                            id={`attenduicon-delete-${index}`}
                            data-previous-id={`attenduicon-valid-${index}, attenduicon-edit-${index}, attenduref_fournisseur-${index}`}
                            data-next-id={`attendureference-${
                              index + 1
                            }, attenduicon-edit-${index + 1}, ${
                              attenduKey['Commentaire']
                            }`}
                          ></i>
                        </Popconfirm>
                      )}
                      {!isAllowDelete && (
                        <i className="icon-delete-disable"></i>
                      )}
                    </Col>
                  </Row>
                </div>
              )
            }}
          />
        </Spin>
      </div>
    </SectionLayout>
  )
}

export default ModalDetailComponent
