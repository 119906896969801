import { SetStateAction } from 'react'
import {
  DataTableParam,
  ForceShowError,
} from '../../../features/stock/stockscreen/component/model'
import { EntryFind } from '../../../http/referenceApi'
import { Conditionnement } from '../../../models'
import { checkIsQteStandard } from '../../../features/stock/modal/type/commande/func'

export function getTotalPieses(
  qte: number,
  currentReference: EntryFind | undefined,
  currentCDN: Conditionnement | undefined
) {
  return (
    qte *
    (currentReference?.conditionnement
      .filter((item) => Number(item.niveau) <= Number(currentCDN?.niveau))
      .reduce(
        (accumulator, currentValue) =>
          Number(accumulator) * Number(currentValue.qte),
        1
      ) || 1)
  )
}

export function getTotalPiesesBySousQte(
  qte: number,
  sousQte: number,
  currentReference: EntryFind | undefined,
  currentCDN: Conditionnement | undefined
) {
  const listCdnFirstNiveau =
    currentReference?.conditionnement.filter(
      (item) => Number(item.niveau) <= Number(currentCDN?.niveau)
    ) || []

  const lengthOfListCdnFirstNiveau = listCdnFirstNiveau.length
  if (lengthOfListCdnFirstNiveau === 0) return 0

  const oldTotalPieses =
    qte *
      listCdnFirstNiveau.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) * Number(currentValue.qte),
        1
      ) || 1

  const lastQte = Number(listCdnFirstNiveau[lengthOfListCdnFirstNiveau - 1].qte)
  const newTotalPieses = (oldTotalPieses / lastQte) * sousQte

  return newTotalPieses
}

export function getSomeIndex(currentLine: DataTableParam[]) {
  const referenceIndex = currentLine.findIndex(
    (item) => item.name === 'reference'
  )
  const refFournisseurIndex = currentLine.findIndex(
    (item) => item.name === 'ref_fournisseur'
  )
  const libelleIndex = currentLine.findIndex((item) => item.name === 'libelle')
  const cdnIndex = currentLine.findIndex((item) => item.name === 'cdn')
  const lotIndex = currentLine.findIndex((item) => item.name === 'lot')
  const ssccIndex = currentLine.findIndex((item) => item.name === 'sscc')
  const dluoIndex = currentLine.findIndex((item) => item.name === 'dluo')
  const qteIndex = currentLine.findIndex((item) => item.name === 'qte')
  const sousQteIndex = currentLine.findIndex((item) => item.name === 'sous_qte')
  const totalPiecesIndex = currentLine.findIndex(
    (item) => item.name === 'total_pieses'
  )
  const volumeQteIndex = currentLine.findIndex(
    (item) => item.name === 'volume_qte'
  )
  const poidsQteIndex = currentLine.findIndex(
    (item) => item.name === 'poids_qte'
  )
  const volumeSousQteIndex = currentLine.findIndex(
    (item) => item.name === 'volume_sousqte'
  )
  const poidsSousQteIndex = currentLine.findIndex(
    (item) => item.name === 'poids_sousqte'
  )
  const conditionementIDIndex = currentLine.findIndex(
    (item) => item.name === 'conditionement_id'
  )
  const conditionementNameIndex = currentLine.findIndex(
    (item) => item.name === 'conditionement'
  )
  const refFournisseurIdIndex = currentLine.findIndex(
    (item) => item.name === 'ref_fournisser_id'
  )
  const referenceIdIndex = currentLine.findIndex(
    (item) => item.name === 'reference_id'
  )
  const isStandardIndex = currentLine.findIndex(
    (item) => item.name === 'is_standard'
  )
  return {
    referenceIndex,
    refFournisseurIndex,
    libelleIndex,
    cdnIndex,
    lotIndex,
    ssccIndex,
    dluoIndex,
    qteIndex,
    sousQteIndex,
    totalPiecesIndex,
    volumeQteIndex,
    poidsQteIndex,
    volumeSousQteIndex,
    poidsSousQteIndex,
    conditionementIDIndex,
    conditionementNameIndex,
    refFournisseurIdIndex,
    referenceIdIndex,
    isStandardIndex,
  }
}

export function getIndexFromForceShowErrorList(
  forceShowError: ForceShowError[]
) {
  const total_pieses_index = forceShowError.findIndex(
    (item) => item.name === 'total_pieses'
  )

  return { total_pieses_index }
}

export function onChangeOnSousQte(
  index: number,
  sousQte: number,
  currentLine: DataTableParam[],
  currentReference: EntryFind | undefined,
  forceShowErrorClone: ForceShowError[][]
) {
  const { totalPiecesIndex, qteIndex, cdnIndex, sousQteIndex } =
    getSomeIndex(currentLine)
  forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
    (item) => item.name !== 'total_pieses'
  )

  const isStandardIndex = currentLine.findIndex(
    (item) => item.name === 'is_standard'
  )
  if (isStandardIndex && currentLine[isStandardIndex]) {
    currentLine[isStandardIndex].value = undefined
  }

  const isQte = getIsQte(currentReference)
  if (!isQte) {
    currentLine[sousQteIndex].value = String(sousQte)
    currentLine[sousQteIndex].confirmMode = false
    currentLine[sousQteIndex].openConfirm = false
    currentLine.push({
      name: 'is_standard',
      value: String(true),
    })
    return { currentLine, forceShowErrorClone }
  }

  let currentCDN = currentReference?.conditionnement.find(
    (e) => e.id === currentLine[cdnIndex].value
  )
  const qte = Number(currentLine[qteIndex].value)

  const total_piece =
    sousQte === 0
      ? ''
      : getTotalPiesesBySousQte(qte, sousQte, currentReference, currentCDN)

  const defaultSousQte = getDefaultSousQte(currentLine, currentReference)

  if (sousQte !== defaultSousQte && sousQte !== 0 && sousQte < 10001) {
    currentLine[sousQteIndex].confirmMode = true
    currentLine[sousQteIndex].openConfirm = true
    currentLine[sousQteIndex].messageConfirm =
      'La quantité standard est modifiée, confirmez la quantité saisie?'
    currentLine[sousQteIndex].valueAfterNotConfirm = String(defaultSousQte)

    currentLine[totalPiecesIndex].value = String(total_piece)
    currentLine.push({
      name: 'is_standard',
      value: String(false),
    })
  } else {
    currentLine[sousQteIndex].confirmMode = currentLine[
      sousQteIndex
    ].openConfirm = false
    currentLine[totalPiecesIndex].value = String(total_piece)
    currentLine.push({
      name: 'is_standard',
      value: String(true),
    })
  }

  return { currentLine, forceShowErrorClone }
}

export function onChangeTotalPieses(
  index: number,
  value: number,
  currentLine: DataTableParam[],
  currentReference: EntryFind | undefined,
  forceShowErrorClone: ForceShowError[][]
) {
  const isQte = checkIsQteStandard(currentReference)
  if (!isQte)
    return {
      currentLine,
      forceShowErrorClone,
    }
  const { totalPiecesIndex, cdnIndex, referenceIndex, qteIndex, sousQteIndex } =
    getSomeIndex(currentLine)
  const currentTotalPieses = value

  let currentCDN = currentReference?.conditionnement.find(
    (e) => e.id === currentLine[cdnIndex].value
  )
  if (!currentCDN) throw new Error('Must pick CDN')
  if (currentTotalPieses === 0 || currentTotalPieses > 10000 * 10000 * 10000) {
    currentLine[sousQteIndex].value = ''
    currentLine[sousQteIndex].confirmMode = currentLine[
      totalPiecesIndex
    ].confirmMode = false
    currentLine[sousQteIndex].openConfirm = currentLine[
      totalPiecesIndex
    ].openConfirm = false
    forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
      (item) => item.name !== 'total_pieses'
    )
    return { currentLine, forceShowErrorClone }
  }

  const isQTE = getIsQte(currentReference)

  if (!isQTE) {
    currentLine[sousQteIndex].confirmMode = currentLine[
      totalPiecesIndex
    ].confirmMode = false
    currentLine[sousQteIndex].openConfirm = currentLine[
      totalPiecesIndex
    ].openConfirm = false
    currentLine[totalPiecesIndex].validates = currentLine[
      totalPiecesIndex
    ].validates?.filter((item) => item.name !== 'confirm')

    return { currentLine, forceShowErrorClone }
  }

  const listCdnFirstNiveau =
    currentReference?.conditionnement.filter(
      (item) => Number(item.niveau) < Number(currentCDN?.niveau)
    ) || []

  const allSousQte = listCdnFirstNiveau?.map((item) => Number(item.qte))

  const isDivisible = allSousQte.every(
    (item) => currentTotalPieses % item === 0
  )
  if (!isDivisible) {
    const { total_pieses_index } = getIndexFromForceShowErrorList(
      forceShowErrorClone[index]
    )
    currentLine[sousQteIndex].value = ''
    if (total_pieses_index === -1)
      forceShowErrorClone[index].push({
        name: 'total_pieses',
        message: 'Sous-qte doit être un nombre entier',
      })
    else
      forceShowErrorClone[index][total_pieses_index].message =
        'Sous-qte doit être un nombre entier'

    currentLine[totalPiecesIndex].confirmMode = currentLine[
      sousQteIndex
    ].confirmMode = false
    currentLine[totalPiecesIndex].openConfirm = currentLine[
      sousQteIndex
    ].openConfirm = false

    currentLine[totalPiecesIndex].disabled = currentLine[
      sousQteIndex
    ].disabled = false
  } else {
    const defaultTotalPieses = getTotalPieses(1, currentReference, currentCDN)

    const multiplePreNiveau = allSousQte.reduce((pre, cur) => cur * pre, 1)
    const newSousQte = currentTotalPieses / multiplePreNiveau
    currentLine[sousQteIndex].value = String(newSousQte)
    forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
      (item) => item.name !== 'total_pieses'
    )

    if (value !== defaultTotalPieses && newSousQte < 10001) {
      currentLine[totalPiecesIndex].confirmMode = true
      currentLine[totalPiecesIndex].openConfirm = true
      currentLine[totalPiecesIndex].messageConfirm =
        'La quantité standard est modifiée, confirmez la quantité saisie?'
      currentLine[totalPiecesIndex].valueAfterNotConfirm =
        String(defaultTotalPieses)
    } else {
      currentLine[totalPiecesIndex].confirmMode = currentLine[
        totalPiecesIndex
      ].openConfirm = false
    }
  }

  return { currentLine, forceShowErrorClone }
}

export function getIsQte(currentReference: EntryFind | undefined) {
  return currentReference?.conditionnement.every((item) => Boolean(item.qte))
}

export const onResetForShowError = (
  name: string,
  index: number,
  forceShowErrorClone: ForceShowError[][],
  setForceShowError: (value: SetStateAction<ForceShowError[][]>) => void
) => {
  forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
    (item) => item.name !== name
  )
  setForceShowError(forceShowErrorClone)
}

const getDefaultSousQte = (
  currentLine: DataTableParam[],
  currentReference: EntryFind | undefined
) => {
  const currentCdnId = currentLine.find((item) => item.name === 'cdn')?.value
  const currentCdn = currentReference?.conditionnement.find(
    (item) => item.id === currentCdnId
  )

  return Number(currentCdn?.qte)
}

export function getStateOfTotalPiesesAndSousQte(
  qte: number,
  currentLine: DataTableParam[],
  references: (EntryFind | undefined)[]
) {
  const disabled = {
    totalPieses: true,
    sousQte: true,
  }

  const refNom = currentLine.find((item) => item.name === 'reference')?.value
  const cdnId = currentLine.find((item) => item.name === 'cdn')?.value

  const currentRef = references.find((item) => item?.nom === refNom)
  const currentCdn = currentRef?.conditionnement.find(
    (item) => item.id === cdnId
  )
  const isQte = checkIsQteStandard(currentRef)

  if (qte > 1 && isQte) return disabled

  if (isQte && qte === 1) {
    disabled.sousQte = disabled.totalPieses = false
  }

  if (!isQte && currentCdn?.niveau === 1) {
    disabled.sousQte = disabled.totalPieses = true
  }
  if (!isQte && currentCdn?.niveau && currentCdn?.niveau > 1) {
    disabled.sousQte = true
    disabled.totalPieses = false
  }

  return disabled
}
